<template>
  <div class="facilityname">
   <strong>【 {{facilityName}} 】</strong>
  </div>
</template>

<script>
import { apiGet } from '../../api'

export default {
  name: 'FacilityName',
  props: {
    facilityId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      facilityName: ''
    };
  },
  mounted() {
    this.loadFacilityName()
  },
  methods: {
    loadFacilityName: async function() {
      try {
        const response = await apiGet(`/api/facility?id=${this.facilityId}`, this.$router.app.$cookies)
        this.facilityName = response.data.name
      } catch (error) {
        console.error(error)
      }
    },
  }
}
</script>

<template>
  <footer>
     <p><span><i>事業再構築</i> &nbsp;Powered by</span><img src="@/assets/img/sl-logo.svg" alt="セキュアリンク株式会社" /></p>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.footer {
  background-color: black;
}
</style>

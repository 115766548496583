<template>
  <Header />
  <main>
    <div class="cont">
      <FacilityName :facilityId="Number($route.query.facility_id)"/>
      <FacilityAdminMenu :facilityId="Number($route.query.facility_id)"/>
      <h1>施設詳細</h1>
      <article class="formitems">
        <div class="form">
          <p>
            <label>施設名</label>
            <input
              placeholder="施設名"
              autocomplete="off"
              type="text"
              v-model="facility.name"
            >
          </p>

          <p>
            <label>住所</label>
            <input
              placeholder="住所"
              autocomplete="off"
              type="text"
              v-model="facility.address"
            >
          </p>

          <p>
            <label>電話番号（半角数字ハイフン無し）</label>
            <input
              placeholder="電話番号（半角数字ハイフン無し）"
              autocomplete="off"
              type="tel"
              v-model="facility.tel"
            >
          </p>

          <p>
            <label>ベッド数(半角数字)</label>
            <input
              placeholder="ベッド数(半角数字)"
              autocomplete="off"
              type="number"
              v-model="facility.bed_count"
            >
          </p>

          <p>
            <label>施設長</label>
            <input
              placeholder="施設長"
              autocomplete="off"
              type="text"
              v-model="facility.manager"
            >
          </p>

          <p>
            <label>担当</label>
            <input
              placeholder="担当"
              autocomplete="off"
              type="text"
              v-model="facility.person_in_charge"
            >
          </p>

          <p>
            <label>連絡先メールアドレス</label>
            <input
              placeholder="連絡先メールアドレス"
              autocomplete="off"
              type="text"
              v-model="facility.contact_email"
            >
          </p>

          <p>
            <label>代表メールアドレス</label>
            <input
              placeholder="代表メールアドレス"
              autocomplete="off"
              type="text"
              v-model="facility.representative_email"
            >
          </p>

          <div class="send">
            <p>
              <span><button v-on:click="saveFacility()">内容を保存</button>
              <label v-if:="saveSuccess">保存しました</label></span>
              <label v-if:="error" class="error">{{ error }}</label>
            </p>
          </div>
        </div>
      </article>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import FacilityAdminMenu from './FacilityAdminMenu'
import FacilityName from './FacilityName'
import { apiGet, apiPost } from '../../api'
import { convertToHankaku } from '../../stringUtil'

export default {
  name: 'EditFacilityAdminUser',
  components: {
    Header,
    Footer,
    FacilityAdminMenu,
    FacilityName,
  },
  data() {
    return {
      facility: {},
      error: null,
      saveSuccess: false
    }
  },
  mounted() {
    this.loadFacility()
  },
  methods: {
    loadFacility: async function() {
      const response = await apiGet(`/api/facility?id=${this.$route.query.facility_id}`, this.$router.app.$cookies)
      this.facility = response.data
    },
    saveFacility: async function() {
      var errors = this.checkFormError()
      if (errors.length > 0) {
        this.error = errors.join()
        return
      }

      const body = {
        id: parseInt(this.$route.query.facility_id),
        name: this.facility.name,
        address: this.facility.address,
        tel: convertToHankaku(String(this.facility.tel)),
        bed_count: this.facility.bed_count,
        manager: this.facility.manager,
        person_in_charge: this.facility.person_in_charge,
        contact_email: this.facility.contact_email,
        representative_email: this.facility.representative_email
      }

      this.saveSuccess = true
      const response = await apiPost('/api/facility', body, this.$router.app.$cookies)
      if (response.data.success) {
        this.$router.push(this.$router.options.history.state.back)
      }
    },
    checkFormError: function() {
      const errors = []
      if (this.facility.tel !== '') {
        const tel_hankaku = convertToHankaku(String(this.facility.tel))
        if (!Number.isInteger(Number(tel_hankaku))) {
          errors.push('電話番号はハイフン無しの数字を入力してください。')
        }
      }

      if (this.facility.contact_email !== null && this.facility.contact_email !== '') {
        if (!this.facility.contact_email.match(/.+@.+\..+/)) {
          errors.push('連絡先メールアドレスをご確認ください')
        }
      }

      if (this.facility.representative_email !== null && this.facility.representative_email !== '') {
        if (!this.facility.representative_email.match(/.+@.+\..+/)) {
          errors.push('代表メールアドレスをご確認ください')
        }
      }

      return errors
    },
  }
}
</script>

<style scoped>
@media all and (max-width: 480px) {
  .form {
    padding: 0 1vw 3vw;
  }
  .form p {
    font-size: 3vw;
    padding-bottom: 1em;
  }
  .form label {
    display: block;
  }
  .form .others label {
    display: none;
  }
  .form input[type=text],
  .form select {
    width: 100%;
    font-size: 3vw;
    padding: .2em;
  }
  .form p span {
    display: block;
    width: 100%;
    padding: 1em 0;
  }
}
@media all and (min-width: 480px) {
  .form {
    padding: 0 1vw 3vw;
  }
  .form p {
    font-size: 1vw;
    padding-bottom: 1em;
  }
  .form label {
    display: block;
  }
  .form .others label {
    display: none;
  }
  .form input,
  .form select {
    font-size: 1vw;
    padding: .2em;
  }
  .form p span {
    display: inline-block;
    padding-right: 1em;
  }
}
</style>

<template>
  <table>
    <thead>
      <tr>
        <th></th>
        <th>部屋番号</th>
        <th>氏名</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="logTargetUser in logTargetUsers" :key="logTargetUser.id">
        <td>
          <input type="checkbox" :value="logTargetUser.id" v-model="checkedTargetUserIdList" />
        </td>
        <td>
          <p>{{ logTargetUser.room_name }}</p>
        </td>
        <td>
          <p>{{ logTargetUser.name }}</p>
        </td>
      </tr>
    </tbody>
  </table>
  <button v-on:click="download">ダウンロード</button>
</template>

<script>
import { apiGet } from '../../api'
import JSZip from 'jszip'

export default {
  props: {
    logTargetUsers: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      checkedTargetUserIdList: []
    }
  },
  methods: {
    download: async function() {
      try {
        const targetUserIdListString = this.checkedTargetUserIdList.join(',')
        const response = await apiGet(`/api/log/download?target_user_id=${targetUserIdListString}`, this.$router.app.$cookies)
        if (response.data === null) {
          return
        }

        const tsvFiles = []
        Object.keys(response.data).forEach((targetUserId) => {
          let lifeLogTsvContent = ''
          response.data[targetUserId]['life_log'].forEach(row => {
            lifeLogTsvContent += row.join('\t') + '\n'
          })

          const lifeLogTsvBlob = new Blob([lifeLogTsvContent], { type: 'text/tsv' })
          tsvFiles.push({ fileName: `${targetUserId}_lifeLog.tsv`, data: lifeLogTsvBlob })

          let sleepTsvContent = ''
          response.data[targetUserId]['sleep'].forEach(row => {
            sleepTsvContent += row.join('\t') + '\n'
          })

          const sleepTsvBlob = new Blob([sleepTsvContent], { type: 'text/tsv' })
          tsvFiles.push({ fileName: `${targetUserId}_sleep.tsv`, data: sleepTsvBlob })
        })

        const zipBlob = await this.createZipFile(tsvFiles)
        this.downloadZipFile(zipBlob, 'log.zip')
      } catch (error) {
        console.log(error)
      }
    },
    createZipFile: async function(csvFiles) {
      const zip = new JSZip()
      csvFiles.forEach((file) => {
        zip.file(file.fileName, file.data)
      })
      const zipBlob = await zip.generateAsync({ type: 'blob' })
      return zipBlob
    },
    downloadZipFile: function(zipBlob, fileName) {
      const url = window.URL.createObjectURL(zipBlob)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      a.click()
      window.URL.revokeObjectURL(url)
    }
  }
}
</script>

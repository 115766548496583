<template>
  <Header />
  <main>
    <div class="cont">
      <FacilityName :facilityId="Number($route.query.facility_id)"/>
      <FacilityAdminMenu :facilityId="Number($route.query.facility_id)"/>
      <h1>ラベル・備考を編集</h1>
      <article class="formitems">
        <div class="form">
          <div v-if:="saveSuccess">
            <label v-if:="saveSuccess">保存しました</label>
          </div>
          <table v-if="facilityLabels.length > 0">
            <thead>
              <tr>
                <th class="label">ラベル</th>
                <th class="note">備考</th>
                <th>
                  <span v-if:="saveSuccess">保存しました</span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="label in facilityLabels" :key="label.id">
                <td>
                  <input
                    placeholder="ラベル（最大12文字）"
                    autocomplete="off"
                    type="text"
                    v-model="label.main"
                  >
                </td>
                <td>
                  <input
                    placeholder="備考"
                    autocomplete="off"
                    type="text"
                    v-model="label.sub"
                  >
                </td>
                <td>
                  <button v-on:click="saveFacilityLabel(label)">保存</button>
                </td>
                <td>
                  <button v-on:click="deleteFacilityLabel(label.id)">削除</button>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    placeholder="ラベル（最大12文字）"
                    autocomplete="off"
                    type="text"
                    v-model="newFacilityLabelMain"
                  >
                </td>
                <td>
                  <input
                    placeholder="備考"
                    autocomplete="off"
                    type="text"
                    v-model="newFacilityLabelSub"
                  >
                </td>
                <td>
                  <button v-on:click="createFacilityLabel()">追加</button>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="errorMessages.length > 0" v-on:click="closeErrorPopup" class="error">
          <div class="popup-overlay">
            <div class="popup-window">
              <p class="popup-text" v-for="errorMessage in errorMessages" :key="errorMessage">{{ errorMessage }}</p>
              <label class="popup-close" for="popup">
                <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
                  <line x1="0" y1="0" x2="18" y2="18" stroke="white" stroke-width="3"></line>
                  <line x1="0" y1="18" x2="18" y2="0" stroke="white" stroke-width="3"></line>
                </svg>
              </label>
            </div>
          </div>
        </div>
      </article>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import FacilityAdminMenu from './FacilityAdminMenu'
import FacilityName from './FacilityName'
import { apiGet, apiPost, apiDelete } from '../../api'
import { convertToHankaku } from '../../stringUtil'

export default {
  name: 'FacilityLabels',
  components: {
    Header,
    Footer,
    FacilityAdminMenu,
    FacilityName,
  },
  data() {
    return {
      facilityLabels: [],
      newFacilityLabelMain: '',
      newFacilityLabelSub: '',
      errorMessages: [],
      saveSuccess: false,
    }
  },
  mounted() {
    this.loadFacilityLabels()
  },
  methods: {
    loadFacilityLabels: async function() {
      const response = await apiGet(`/api/facility_label?facility_id=${this.$route.query.facility_id}`, this.$router.app.$cookies)
      this.facilityLabels = response.data
    },
    saveFacilityLabel: async function(label) {
      this.validateInput(label)
      if (this.errorMessages.length > 0) {
        return
      }

      const body = {
        facility_label: {
          id: label.id,
          facility_id: label.facility_id,
          main: convertToHankaku(label.main),
          sub: convertToHankaku(label.sub)
        },
      }

      this.saveSuccess = true
      const response = await apiPost('/api/facility_label', body, this.$router.app.$cookies)
      if (response.data.success) {
        location.reload()
      }
    },
    createFacilityLabel: async function() {
      if (this.newFacilityLabelMain === '' && this.newFacilityLabelSub === '') {
        return
      }

      const body = {
        facility_label: {
          facility_id: this.$route.query.facility_id,
          main: convertToHankaku(this.newFacilityLabelMain),
          sub: convertToHankaku(this.newFacilityLabelSub),
        }
      }

      this.saveSuccess = true
      const response = await apiPost('/api/facility_label', body, this.$router.app.$cookies)
      if (response.data.success) {
        location.reload()
      }
    },
    deleteFacilityLabel: async function(labelId) {
      const body = {
        id: labelId,
      }
      const response = await apiDelete('/api/facility_label', body, this.$router.app.$cookies)
      if (response.data.success) {
        location.reload()
      }
    },
    validateInput: function(label) {
      this.errorMessages = []
      if (label.main.length > 12) {
        this.errorMessages.push('ラベルは最大12文字です。')
      }
    },
    closeErrorPopup: function() {
      this.errorMessages = []
    },
  }
}
</script>

<style scoped>
@media all and (max-width: 480px) {
  .form {
    padding: 0 1vw 3vw;
    font-size: 3vw;
  }
  .form table th {
    display: none;
  }
  .form table td {
    display: inline-block;
    width: 100%;
    padding: 0 1em .5em 0;
  }
  .form table td:nth-of-type(n + 3) {
    display: inline;
    padding-bottom: 6vw;
  }
  .form table td:last-child {
    display: inline-block;
    width: 50%;
    padding-bottom: 6vw;
  }
  .form input {
    font-size: 3vw;
    width: 100%;
  }
  .form label {
    display: inline-block;
    width: 11vw;
  }
  .form .multiple label:nth-of-type(n + 2) {
    width: inherit !important;
    padding-right: 1em;
  }
  .form em {
    display: block;
    font-size: 3vw;
    font-style: normal;
  }
  .form hr {
    height: 1px;
    padding: 0;
    border: 0;
    border-bottom: rgb(100, 145, 200) dashed 1px;
  }
  .error {
    text-align: center;
  }
  .error label {
    font-size: 4.2vw;
  }
  .popup-overlay {
    display: block;
    z-index: 99999;
    background-color: #00000070;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }

  .popup-window {
    width: 90vw;
    max-width: 560px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 6px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .popup-close {
    cursor: pointer;
    position: absolute;
    top: -26px;
    right: 0;
  }
  .error .popup-text {
    font-size: 4.2vw;
  }
}
@media all and (min-width: 480px) {
  .form {
    padding: 0 1vw 3vw;
    font-size: 1vw;
  }
  .form table th {
    font-weight: 300;
    padding-bottom: .5em;
  }
  .form table td {
    padding: 0 1em .5em 0;
  }
  .form table th.label {
    width: 14em;
  }
  .form table th.note {
    width: 28em;
  }
  .form input {
    font-size: 1vw;
  }
  .form label {
    display: inline-block;
    width: 11vw;
  }
  .form .multiple label:nth-of-type(n + 2) {
    width: inherit !important;
    padding-right: 1em;
  }
  .form em {
    display: block;
    font-size: 1.1vw;
    font-style: normal;
  }
  .form hr {
    height: 1px;
    padding: 0;
    border: 0;
    border-bottom: rgb(100, 145, 200) dashed 1px;
  }
  .error {
    text-align: center;
  }
  .error label {
    font-size: 1.2vw;
  }
  .popup-overlay {
    display: block;
    z-index: 99999;
    background-color: #00000070;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
  .popup-window {
    width: 90vw;
    max-width: 560px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 6px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .popup-close {
    cursor: pointer;
    position: absolute;
    top: -26px;
    right: 0;
  }
  .error .popup-text {
    font-size: 1.2vw;
  }
}
</style>

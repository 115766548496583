<template>
  <i><a v-on:click="goBack">戻る</a></i>
</template>

<script>
export default {
  name: 'BackButton',
  methods: {
    goBack: function() {
      history.back()
    }
  }
}
</script>

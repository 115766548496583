<template>
  <main class="idx">
    <div class="idx-login">
      <dl>
        <dt><img src="@/assets/img/logo-mark.svg" alt="AI Care" /></dt>
        <dd>
          <img src="@/assets/img/sl-logo-blue.svg" />
          <div class="form-item">
            <label for="email">メールアドレスを入力してください</label>
            <input
              id="email"
              placeholder="メールアドレス"
              autocomplete="off"
              type="text"
              v-model="email"
            >
          </div>
          <div class="form-item">
            <button class="button" @click="sendCode()">認証コードを送信</button>
          </div>
          <div class="form-item">
            <a href="/">TOPへ戻る</a>
          </div>
        </dd>
      </dl>
    </div>
  </main>
  <Footer />
</template>

<script>
import axios from 'axios'
import Footer from './Footer'

export default {
  name: 'ForgotPassword',
  components: {
    Footer,
  },
  data () {
    return {
      email: '',
    }
  },
  methods: {
    sendCode: function() {
      axios.post('/api/auth/forgot_password', {
        email: this.email,
      }).then((res) => {
        if (res.data.success) {
          this.$router.push(`/reset_password?email=${this.email}`)
        } else {
          alert('メールアドレスを確認してください')
        }
      })
    }
  }
}
</script>

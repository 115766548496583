<template>
  <Header />
  <main>
    <div class="cont">
      <vue-element-loading :active="isLoading" background-color="rgba(255, 255, 255, .5)" color="#2c3e50"/>

      <div v-if="facility">
        <em class="backbtn"><span class="linktxt" v-on:click="showSelectFacility">施設選択へ戻る</span></em>
        <h1>{{ facility.name }}</h1>
        <div class="info-notes">
          <p v-if="facility.address">{{ facility.address }}<br><span class="linktxt" v-on:click="showFacilityConfigMenu">施設設定</span></p>
          <p v-if="facility.tel">（{{ facility.tel }}）</p>
          <p>
            <span v-if="facility.bed_count">（ベッド数：{{ facility.bed_count }}台）</span>
            <span v-if="facility.manager">施設長：{{ facility.manager }}</span>
            <span v-if="facility.person_in_charge">担当：{{ facility.person_in_charge }}</span>
          </p>
        </div>

        <TargetUserSearchForm @searchTargetUsers="searchTargetUsers"/>

        <div class="panels">
          <p class="slct-person">
            <select v-model="personInCharge">
              <option value="">担当者の指定なし</option>
              <option v-for="name in peopleInChargeOfAll" :key="name" :value="name">{{ name }}</option>
              <option value="-">担当者未設定</option>
            </select>
            <button v-on:click="renderTargetUsers">表示</button>
          </p>

          <dl>
            <dt>グルーピング</dt>
            <dd>
              <label><input type="radio" name="GroupingType" v-model="groupingType" :value="groupingTypeConstant.label" v-on:click="setGroupingType(groupingTypeConstant.label)"/>ラベル</label>
              <label><input type="radio" name="GroupingType" v-model="groupingType" :value="groupingTypeConstant.personInCharge" v-on:click="setGroupingType(groupingTypeConstant.personInCharge)" />担当者</label>
            </dd>
          </dl>

          <dl>
            <dt>表示形式</dt>
            <dd>
              <label><input type="radio" name="ListType" v-model="listType" :value="listTypeConstant.icon" v-on:click="setListType(listTypeConstant.icon)" />アイコン</label>
              <label><input type="radio" name="ListType" v-model="listType" :value="listTypeConstant.list" v-on:click="setListType(listTypeConstant.list)" />リスト</label>
            </dd>
          </dl>

          <dl>
            <dt>名前</dt>
            <dd>
              <label><input type="radio" name="NameVisibility" v-model="nameVisibility" :value="true" v-on:click="setNameVisibility(true)" />表示</label>
              <label><input type="radio" name="NameVisibility" v-model="nameVisibility" :value="false" v-on:click="setNameVisibility(false)" />非表示</label>
            </dd>
          </dl>

          <dl>
            <dt>担当者</dt>
            <dd>
              <label><input type="radio" name="PeopleInCharge" v-model="peopleInChargeVisibility" :value="true" v-on:click="setPeopleInChargeVisibility(true)" />表示</label>
              <label><input type="radio" name="PeopleInCharge" v-model="peopleInChargeVisibility" :value="false" v-on:click="setPeopleInChargeVisibility(false)" />非表示</label>
            </dd>
          </dl>
        </div>

        <div>
          <div class="noresults" v-if="renderedTargetUsers.length === 0"><span>該当なし</span></div>
          <TargetUserList v-if="renderedTargetUsers.length !== 0" @showUserDetail="showUserDetail"
            :targetUserGroups="renderedTargetUsers" :listType="listType"
            :nameVisibility="nameVisibility" :peopleInChargeVisibility="peopleInChargeVisibility"/>
        </div>

      </div>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import { apiGet, apiPost } from '../../api'
import TargetUserSearchForm from './TargetUserSearchForm'
import TargetUserList from './TargetUserList'
import {groupingType, targetUserListType} from '../../constants'

export default {
  name: 'FacilityManager',
  components: {
    Header,
    Footer,
    TargetUserSearchForm,
    TargetUserList,
  },
  data() {
    return {
      facility: null,
      targetUsers: [],
      filteredTargetUsers: [],
      renderedTargetUsers: [],
      detailData: {
        targetUserId: null,
        startTime: new Date()
      },
      isLoading: true,
      currentSearchQuery: `facility_id=${this.$route.query.id}`,
      personInCharge: '',
      peopleInChargeOfAll: [],
      groupingType: groupingType.label,
      groupingTypeConstant: groupingType,
      listType: targetUserListType.icon,
      listTypeConstant: targetUserListType,
      nameVisibility: true,
      peopleInChargeVisibility: true,
    }
  },
  mounted() {
    this.isLoading = true
    this.loadFacility()
    this.loadUserFacilitySettings()
    this.startFetchingTargetUserList()
  },
  methods: {
    showUserDetail: function(targetUserId) {
      this.$router.push(`/target_user_detail/real_time?facility_id=${this.$route.query.id}&target_user_id=${targetUserId}`)
    },
    loadFacility: async function() {
      this.isLoading = true
      try {
        const response = await apiGet(`/api/facility?id=${this.$route.query.id}`, this.$router.app.$cookies)
        this.facility = response.data
      } catch (error) {
        console.error(error)
      }
    },
    heartbeatRealtimeSensorData: function() {
      this.targetUsers.forEach(targetUser => {
        try {
          apiPost('/api/rpm_sensor_data/heartbeat',{
            target_user_id: targetUser.id
          }, this.$router.app.$cookies)
        } catch (error) {
          console.error(error.value)
        }
      })
    },
    startFetchingTargetUserList: async function() {
      await this.loadTargetUserList()

      // update after 3 seconds
      this.heartbeatRealtimeSensorData()
      const loadTargetUserListTimeIntarval = 3000
      const _this = this
      setTimeout(function() {
        _this.loadTargetUserList()
      }, loadTargetUserListTimeIntarval)
    },
    loadTargetUserList: async function() {
      if (!Number.isInteger(Number(this.$route.query.id))) {
        return
      }
      try {
        const response = await apiGet(`/api/target_user/search?${this.currentSearchQuery}`, this.$router.app.$cookies)
        this.targetUsers = response.data

        this.targetUsers.forEach(targetUser => {
          if (targetUser.facility_user_names_in_charge) {
            targetUser.facility_user_names_in_charge.forEach(nameInCharge => {
              this.peopleInChargeOfAll.push(nameInCharge)
            })
          }
        })
        this.peopleInChargeOfAll = [...new Set(this.peopleInChargeOfAll)]
        this.renderTargetUsers()
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    },
    searchTargetUsers: function(targetUsers, query) {
      this.targetUsers = targetUsers
      this.currentSearchQuery = query
      this.renderTargetUsers()
    },
    showSelectFacility: function() {
      this.$router.push('/select_facility')
    },
    showFacilityConfigMenu: function() {
      this.$router.push(`/facility_config_menu?id=${this.$route.query.id}`)
    },
    renderTargetUsers: function() {
      this.updatePersonInCharge()

      const groupedTargetUsers = []
      this.filteredTargetUsers.forEach(targetUser => {
        if (this.groupingType === this.groupingTypeConstant.label) {
          if (targetUser.main_label === null) {
            if ('未設定' in groupedTargetUsers) {
              groupedTargetUsers['未設定'].push(targetUser)
            } else {
              groupedTargetUsers['未設定'] = [targetUser]
            }
            return
          }

          if (targetUser.main_label in groupedTargetUsers) {
            groupedTargetUsers[targetUser.main_label].push(targetUser)
          } else {
            groupedTargetUsers[targetUser.main_label] = [targetUser]
          }
        } else if (this.groupingType === this.groupingTypeConstant.personInCharge) {
          if (targetUser.facility_user_names_in_charge.length === 0) {
            if ('未設定' in groupedTargetUsers) {
              groupedTargetUsers['未設定'].push(targetUser)
            } else {
              groupedTargetUsers['未設定'] = [targetUser]
            }
            return
          }

          const nameInCharge = targetUser.facility_user_names_in_charge
          nameInCharge.forEach(name => {
            if (name in groupedTargetUsers) {
              groupedTargetUsers[name].push(targetUser)
            } else {
              groupedTargetUsers[name] = [targetUser]
            }
          })
        }
      })

      this.renderedTargetUsers = []
      Object.keys(groupedTargetUsers).forEach(key => {
        this.renderedTargetUsers.push({
          groupName: key,
          targetUsers: groupedTargetUsers[key]
        })
      })

      this.renderedTargetUsers.sort((a, b) => {
        if (a.groupName === '未設定') {
          return 1
        }
        if (b.groupName === '未設定') {
          return -1
        }
        if (a.groupName > b.groupName) {
          return 1
        } else if (a.groupName < b.groupName) {
          return -1
        } else {
          return 0
        }
      })
    },
    updatePersonInCharge: function() {
      if (this.personInCharge === "") {
        this.filteredTargetUsers = this.targetUsers
        return
      } else if (this.personInCharge === "-") {
        this.filteredTargetUsers = this.targetUsers.filter( targetUser => {
          return targetUser.facility_user_names_in_charge.length === 0
        })
        return
      }

      this.filteredTargetUsers = this.targetUsers.filter( targetUser => {
        if (targetUser.facility_user_names_in_charge.length === 0) {
          return false
        }
        return targetUser.facility_user_names_in_charge.includes(this.personInCharge)
      })
    },
    setGroupingType: function(value) {
      this.groupingType = value
      this.renderTargetUsers()
      this.saveUserFacilitySettings()
    },
    setListType: function(value) {
      this.listType = value
      this.saveUserFacilitySettings()
    },
    setNameVisibility: function(value) {
      this.nameVisibility = value
      this.saveUserFacilitySettings()
    },
    setPeopleInChargeVisibility: function(value) {
      this.peopleInChargeVisibility = value
      this.saveUserFacilitySettings()
    },
    loadUserFacilitySettings: async function() {
      try {
        const response = await apiGet(`/api/user_facility_setting?facility_id=${this.$route.query.id}`, this.$router.app.$cookies)
        if (response.data === '') {
          return
        }

        this.groupingType = response.data.grouping_type
        this.listType = response.data.list_type
        this.nameVisibility = response.data.name_visibility === 0 ? false : true
        this.peopleInChargeVisibility = response.data.people_in_charge_visibility === 0 ? false : true
      } catch (error) {
        console.error(error)
      }
    },
    saveUserFacilitySettings: async function() {
      try {
        const body = {
          facility_id: this.$route.query.id,
          grouping_type: this.groupingType,
          list_type: this.listType,
          people_in_charge_visibility: this.peopleInChargeVisibility,
          name_visibility: this.nameVisibility
        }

        await apiPost('/api/user_facility_setting', body, this.$router.app.$cookies)
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
<style scoped>
@media all and (max-width: 480px) {
  em.backbtn {
    display: block;
    font-style: normal;
    font-size: 3vw;
    padding-bottom: 1em;
  }
  .info-notes {
    font-size: 3.4vw;
    padding-bottom: 2vw;
  }
  .info-notes p span {
    display: block;
  }
  .panels {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 4vw 0;
    width: 100vw;
    white-space: nowrap;
    margin-left: -3vw;
  }
  .panels .slct-person {
    width: 100%;
    text-align: center;
    padding-bottom: 1.5em;
  }
  .panels .slct-person select {
    font-size: 3.8vw;
    margin-right: 1em;
  }
  .panels dl {
    padding: 0 .5vw;
  }
  .panels dl dt {
    text-align: center;
    font-size: 3vw;
  }
  .panels dl dd  {
    font-size: 2vw;
    padding: .5VW 0;
  }
  .panels dl dd label {
    padding: .2em .1em;
    cursor: pointer;
    vertical-align: text-top;
  }
  .panels dl dd input[type = "radio"] {
    width: 3vw;
    height: 3vw;
    vertical-align: text-top;
  }
  .noresults {
    text-align: center;
    font-size: 3.8vw;
    padding: 2em 0 4em;
  }
  .noresults span {
    display: block;
    width: 80%;
    margin: auto;
    padding: 1em 0;
    border-top: rgb(200,200,200) double 3px;
    border-bottom: rgb(200,200,200) double 3px;
  }
}
@media all and (min-width: 480px) {
  em.backbtn {
    display: block;
    text-align: right;
    font-style: normal;
    font-size: 1vw;
  }
  .info-notes {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    font-size: 1vw;
    padding-bottom: 1.2vw;
  }
  .info-notes p:last-child {
    margin-left: auto;
  }
  .info-notes p span {
    display: block;
  }
  .noresults {
    text-align: center;
    font-size: 1vw;
    padding: 2em 0 4em;
  }
  .noresults span {
    display: block;
    width: 80%;
    margin: auto;
    padding: 1em 0;
    border-top: rgb(200,200,200) double 3px;
    border-bottom: rgb(200,200,200) double 3px;
  }
  .panels {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
  .panels .slct-person {
    width: 100%;
    text-align: center;
    padding-bottom: 1.5em;
  }
  .panels .slct-person select {
    font-size: 1vw;
    margin-right: 1em;
  }
  .panels dl {
    padding: 0 1vw;
  }
  .panels dl dt {
    text-align: center;
    font-size: 1vw;
  }
  .panels dl dd  {
    font-size: 1vw;
  }
  .panels dl dd label {
    padding: .2em .5em;
    cursor: pointer;
    vertical-align: text-top;
  }
  .panels dl dd input[type = "radio"] {
    width: 1vw;
    height: 1vw;
    vertical-align: text-top;
  }
}
</style>

<template>
  <main class="idx">
    <div class="idx-login">
      <dl>
        <dt><img src="@/assets/img/logo-mark.svg" alt="AI Care" /></dt>
        <dd>
          <img src="@/assets/img/sl-logo-blue.svg" />
          <div class="form-item">
            <label for="code">メールに記載されている認証コードを入力してください</label>
            <input
              id="code"
              placeholder="認証コード"
              autocomplete="off"
              type="text"
              v-model="code"
            >
          </div>
          <div class="form-item">
            <label for="password">新しいパスワード</label>
            <input
              id="password"
              placeholder="新しいパスワード(8文字以上)"
              autocomplete="off"
              type="password"
              v-model="password"
            >
          </div>
          <div class="form-item">
            <button class="button" @click="resetPassword()">パスワードをリセット</button>
          </div>
          <div class="form-item">
            <a href="/">TOPへ戻る</a>
          </div>
        </dd>
      </dl>
    </div>
  </main>
  <Footer />
</template>

<script>
import axios from 'axios'
import Footer from './Footer'

export default {
  name: 'ResetPassword',
  components: {
    Footer,
  },
  data () {
    return {
      password: '',
      code: '',
    }
  },
  methods: {
    resetPassword: function() {
      axios.post('/api/auth/reset_password', {
        email: this.$route.query.email,
        password: this.password,
        code: this.code
      }).then((res) => {
        if (res.data.success) {
          this.$router.push(`/login?reset_password_success=1`)
        } else {
          alert("リセットに失敗しました。")
        }
      })
    }
  }
}
</script>

<template>
  <Header />
  <main>
    <div class="cont">
      <BackButton/>
      <h1>会社マスタ</h1>

      <label for="searchCompanyName">運営会社名</label>
      <input
        id="searchCompanyName"
        placeholder="運営会社名"
        autocomplete="off"
        type="text"
        v-model="searchCompanyName"
      >

      <label for="searchFacilityName">施設名</label>
      <input
        id="searchFacilityName"
        placeholder="施設名"
        autocomplete="off"
        type="text"
        v-model="searchFacilityName"
      >
      <br>

      <input type="radio" id="searchCompanyStatusOption1" :value="companyStatus.active" v-model="searchCompanyStatus" />
      <label for="searchCompanyStatusOption1">契約中</label>
      <br>
      <input type="radio" id="searchCompanyStatusOption2" :value="companyStatus.cancel" v-model="searchCompanyStatus" />
      <label for="searchCompanyStatusOption2">契約破棄</label>
      <br>
      <input type="radio" id="searchCompanyStatusOption3" :value="companyStatus.inactive" v-model="searchCompanyStatus" />
      <label for="searchCompanyStatusOption3">中断</label>
      <br>

      <button v-on:click="searchCompany">検索</button>
      <br>

      <ul>
        <li v-for="company in hitCompanies" :key="company.id">
          <label>{{ company.name }}</label>
          <button v-on:click="editCompany(company.id)">編集</button>
        </li>
      </ul>

      <button v-on:click="editCompany()">新規作成</button>
      <br>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import { apiGet } from '../../api'
import { companyStatus } from '../../constants'

export default {
  name: 'CompanyMaster',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      searchCompanyName: '',
      searchFacilityName: '',
      searchCompanyStatus: companyStatus.active,
      hitCompanies: [],
      companyStatus: companyStatus
    }
  },
  methods: {
    searchCompany: async function() {
      var query = `status=${this.searchCompanyStatus}`
      if (this.searchCompanyName !== '') {
        query += `&name=${this.searchCompanyName}`
      }
      if (this.searchFacilityName !== '') {
        query += `&facility_name=${this.searchFacilityName}`
      }
      const response = await apiGet(`/api/admin/company/search?${query}`, this.$router.app.$cookies)
      this.hitCompanies = response.data
    },
    editCompany: function (companyId) {
      if (companyId) {
        this.$router.push(`/admin/edit_company?id=${companyId}`)
      } else {
        this.$router.push(`/admin/edit_company`)
      }
    }
  }
}
</script>

<template>
  <Header />
  <main>
    <div class="cont">
      <FacilityAdminMenu :facilityId="Number($route.query.facility_id)"/>
      <h1>施設担当者詳細</h1>

      <article class="formitems">
        <div class="form">
          <p>
            <label>氏名</label>
            <input
              placeholder="氏名"
              autocomplete="off"
              type="text"
              v-model="user.name"
            >
          </p>

          <p>
            <label>メールアドレス</label>
            <input
              placeholder="メールアドレス"
              autocomplete="off"
              type="text"
              v-model="user.email"
            >
          </p>

          <p>
            <label>権限</label>
            <label><input type="radio" :value="1" v-model="user.is_facility_admin" />
            施設管理者</label>
            <label><input type="radio" :value="0" v-model="user.is_facility_admin" />
            一般職員</label>
          </p>

          <p>
            <label>ステータス</label>
            <label><input type="radio" :value="userStatus.active" v-model="user.status" />
            利用中</label>
            <label><input type="radio" :value="userStatus.inactive" v-model="user.status" />
            停止中</label>
          </p>

          <div class="send">
            <p>
              <span><button v-on:click="saveUser()">内容を保存</button>
              <label v-if:="saveSuccess">保存しました</label></span>
            </p>
          </div>
        </div>
      </article>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import FacilityAdminMenu from './FacilityAdminMenu'
import { apiGet, apiPost } from '../../api'
import { userStatus } from '../../constants'
import { convertToHankaku } from '../../stringUtil'

export default {
  name: 'EditFacilityAdminUser',
  components: {
    Header,
    Footer,
    FacilityAdminMenu
  },
  data() {
    return {
      user: {},
      userStatus: userStatus,
      saveSuccess: false
    }
  },
  mounted() {
    this.loadUser()
  },
  methods: {
    loadUser: async function() {
      const response = await apiGet(`/api/user_detail?id=${this.$route.query.user_detail_id}&facility_id=${this.$route.query.facility_id}`, this.$router.app.$cookies)
      this.user = response.data
    },
    saveUser: async function() {
      const body = {
        user_detail: {
          id: this.user.id,
          user_id: this.user.user_id,
          name: this.user.name,
          email: convertToHankaku(this.user.email),
          status: this.user.status
        },
        facility_id: Number(this.$route.query.facility_id),
        user_facility_relation_id: this.user.user_facility_relation_id,
        is_facility_admin: this.user.is_facility_admin,
      }
      this.saveSuccess = true
      const response = await apiPost('/api/user_facility_relation', body, this.$router.app.$cookies)
      if (response.data.success) {
        this.$router.push(this.$router.options.history.state.back)
      }
    },
  }
}
</script>

<style scoped>
@media all and (max-width: 480px) {
  .form {
    padding: 0 1vw 3vw;
  }
  .form p {
    font-size: 3vw;
    padding-bottom: 1em;
  }
  .form label {
    display: block;
  }
  .form .others label {
    display: none;
  }
  .form input[type=text],
  .form select {
    width: 100%;
    font-size: 3vw;
    padding: .2em;
  }
  .form p span {
    display: block;
    width: 100%;
    padding: 1em 0;
  }
}
@media all and (min-width: 480px) {
  .form {
    padding: 0 1vw 3vw;
  }
  .form p {
    font-size: 1vw;
    padding-bottom: 1em;
  }
  .form label {
    display: block;
  }
  .form .others label {
    display: none;
  }
  .form input,
  .form select {
    font-size: 1vw;
    padding: .2em;
  }
  .form p span {
    display: inline-block;
    padding-right: 1em;
  }
}
</style>

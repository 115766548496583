<template>
  <Header />
    <main>
      <div class="cont">
        <BackButton/>
        <h1>プライバシーポリシー</h1>
        <h2>■ はじめに</h2>
        <p>セキュアリンク株式会社（以下「弊社」といいます。）は、個人情報の保護を社会的責務であると考え、お客様に安心してご利用いただけるウェブサイトの管理・運営を行うため、「個人情報保護方針」に基づき、以下のとおり「個人情報の取扱いについて」を定めます。</p>
        <h2>■ 個人情報保護方針</h2>
        <dl>
          <dt>1.個人情報の定義</dt>
          <dd>
            <p>「個人情報」とは、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの、及び他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものをいいます。</p>
          </dd>
          <dt>2.個人情報の取得</dt>
          <dd>
            <p>当サイトでは、ユーザー登録、商品のご購入、お問い合わせをされた際にお客様（以下「ユーザー」といいます。）の個人情報を取得することがございます。取得するにあたっては利用目的を明記の上、適法かつ公正な手段によります。</p>
            <p>当サイトで取得する個人情報は以下の通りです。</p>
            <ul>
              <li>お名前、フリガナ</li>
              <li>ご住所</li>
              <li>お電話番号</li>
              <li>メールアドレス</li>
              <li>性別</li>
              <li>勤務先</li>
              <li>生年月日</li>
              <li>製品及び弊社サービス利用に関するパスワード</li>
              <li>配送先情報</li>
              <li>当サイトでのお取引履歴及びその内容</li>
              <li>上記を組み合わせることで特定の個人が識別できる情報</li>
            </ul>
          </dd>
          <dt>3.個人情報の利用</dt>
          <dd>
            <p>ユーザーからお預かりした個人情報の利用目的は以下の通りです。</p>
            <ul>
              <li>ユーザーサービスに関するメール等でのご案内</li>
              <li>オンラインショップでのご注文の確認、照会</li>
              <li>商品発送の確認、照会</li>
              <li>各種お問合わせの対応</li>
              <li>サービス提供上必要となる弊社からの問合せ</li>
              <li>サービス向上のためのユーザーからの意見収集</li>
            </ul>
              <p>当サイトでは、以下の場合を除いては、ユーザーの断りなく第三者に個人情報を開示・提供することはいたしません。</p>
              <p>法令に基づく場合、及び国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合<br>
              人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合</p>
          </dd>
          <dt>4.委託先の監督</dt>
          <dd>
            <p>弊社は、サービスの提供上必要な場合、個人情報の一部を外部の委託先へ提供する場合があります。その場合、当該委託先が個人情報の適切な取り扱いに努めるよう監督を行います。</p>
          </dd>
          <dt>5.個人情報の安全管理</dt>
          <dd>
            <p>ユーザーよりお預かりした個人情報の安全管理は、合理的、組織的、物理的、人的、技術的施策を講じるとともに、当サイトでは関連法令に準じた適切な取扱いを行うことで個人データへの不正な進入、個人情報の紛失、改ざん、漏洩等の危険防止に努めます。</p>
          </dd>
          <dt>6.個人情報の訂正、削除</dt>
          <dd>
            <p>ユーザーからお預かりした個人情報について、開示･訂正・削除・利用停止・消去等を求められた場合、当該個人情報のご本人であることを確認させて頂いた上で、合理的な期間内に対応させて頂きます。これらに関する請求は、下記 9.お問い合わせ先欄記載のメールアドレスにご連絡ください。</p>
          </dd>
          <dt>7.Cookie（クッキー）の使用について</dt>
          <dd>
            <p>弊社は、ユーザーによりよいサービスを提供するため、cookie（クッキー）を使用することがありますが、これにより個人を特定できる情報の取得を行えるものではなく、ユーザーのプライバシーを侵害することはございません。また、cookie（クッキー）の受け入れを希望されない場合はブラウザの設定で変更することができます。<br>
            ※cookie（クッキー）とは、サーバーコンピュータからユーザーのブラウザに送信され、ユーザーが使用しているコンピュータのハードディスクに蓄積される情報です。</p>
          </dd>
          <dt>8.SSLの使用について</dt>
          <dd>
            <p>個人情報の入力時には、セキュリティ確保のため、これらの情報が傍受、妨害または改ざんされることを防ぐ目的で SSL（Secure Sockets Layer）技術を使用しております。<br>
            ※SSL は情報を暗号化することで、盗聴防止やデータの改ざん防止送受信する機能のことです。SSL を利用する事でより安全に情報を送信する事が可能となります。</p>
          </dd>
          <dt>9.お問い合わせ先</dt>
          <dd>
            <p>お問い合わせ先：info@securelink-inc.co.jp</p>
          </dd>
          <dt>10.プライバシーポリシーの変更</dt>
          <dd>
            <p>当サイトでは、取得する個人情報の変更、利用目的の変更、またはプライバシーポリシーの変更を行う際は、当ページの変更をもって公表とさせていただきます。</p>
          </dd>
        </dl>
      </div>
    </main>
  <Footer />
</template>

<script>
import Header from './Header'
import Footer from './Footer'

export default {
  name: 'PrivacyPolicy',
  components: {
    Header,
    Footer
  },
}
</script>

<style scoped>
@media all and (max-width: 480px) {
  .cont h2 {
    font-size: 4.4vw;
    padding-bottom: .5em;
  }
  .cont > p {
    font-size: 3.8vw;
    padding-bottom: 2em;
  }
  .cont dl {
    font-size: 3.8vw;
  }
  .cont dl dt {
    color: rgb(70, 125, 210);
  }
  .cont dl dd {
    padding: 0 0 1em 1em;
  }
  .cont dl dd ul li::before {
    content: '・';
  }
}
@media all and (min-width: 480px) {
  .cont h2 {
    font-size: 1.2vw;
    padding-bottom: .5em;
  }
  .cont > p {
    font-size: 1vw;
    padding-bottom: 2em;
  }
  .cont dl {
    font-size: 1vw;
  }
  .cont dl dt {
    color: rgb(70, 125, 210);
  }
  .cont dl dd {
    padding: 0 0 1em 1em;
  }
  .cont dl dd ul li::before {
    content: '・';
  }
}
</style>

<template>
  <Header />
  <main>
    <div class="cont">
      <BackButton/>
      <h1>お問い合わせ</h1>
      <ul>
        <li>
          <select name="種類" v-model="category">
            <option value="" selected>お問い合わせの種類</option>
            <option value="サービスについて">サービスについて</option>
            <option value="機器について">機器について</option>
            <option value="サイトについて">サイトについて</option>
            <option value="登録情報・退会について">登録情報・退会について</option>
            <option value="その他ご意見・ご要望">その他ご意見・ご要望</option>
          </select><span>必須</span>
        </li>
        <li><textarea placeholder="お問い合わせ" v-model="body" /></li>
        <li><hr></li>
        <li><input type="text" v-model="company" name="御社名" size="50" placeholder="御社名"></li>
        <li><input type="text" v-model="name" name="お名前" size="50" placeholder="お名前"><span>必須</span></li>
        <li><input type="email" v-model="email" data-type="email" name="email" size="50" placeholder="メールアドレス"><span>必須</span></li>
        <li>
          <select name="地域" v-model="prefecture">
            <option value="" selected>所属またはお住まいの地域</option>
            <option v-for="item in prefectures" :key="item" :value="item">{{ item }}</option>
          </select><span>必須</span>
        </li>
        <li>
          <button @click="send()">送信</button>
          <label v-if:="sendFinish">送信しました</label>
          <div v-for="errorMessage in errorMessages" :key="errorMessage">
            <label>{{ errorMessage }}</label><br/>
          </div>
        </li>
        <li>
          <label>※返信までにお時間がかかる場合があります。</label>
        </li>

      </ul>
    </div>
  </main>
  <Footer />
</template>

<script>
import axios from 'axios'
import Header from './Header'
import Footer from './Footer'
import { prefectures } from '../constants'

export default {
  name: 'Inquiry',
  components: {
    Header,
    Footer
  },
  data () {
    return {
      category: '',
      body: '',
      prefecture: '',
      company: '',
      name: '',
      email: '',
      sendFinish: false,
      prefectures: prefectures,
      errorMessages: []
    }
  },
  methods: {
    send: async function() {
      try {
        this.validateInput()
        if (this.errorMessages.length > 0) {
          return
        }

        this.sendFinish = true
        const sendResponse = await axios.post('/api/inquiry', {
          category: this.category,
          body: this.body,
          prefecture: this.prefecture,
          company: this.company,
          name: this.name,
          email: this.email
        })
        if (sendResponse.data.success) {
          history.back()
        }
      } catch(err) {
        console.error(err)
      }
    },
    validateInput: function() {
      this.errorMessages = []
      if (this.category === '') {
        this.errorMessages.push('お問い合わせの種類は必須です。')
      }
      if (this.body === '') {
        this.errorMessages.push('お問い合わせ本文は必須です。')
      }
      if (this.name === '') {
        this.errorMessages.push('お名前は必須です。')
      }
      if (this.email === '') {
        this.errorMessages.push('メールアドレスは必須です。')
      }
      if (this.prefecture === '') {
        this.errorMessages.push('地域は必須です。')
      }
    }
  }
}
</script>

<style scoped>
@media all and (max-width: 480px) {
  .cont ul {
  width: 96vw;
  margin: auto;
  font-size: 3.8vw;
  padding: .5em 0;
}
.cont ul li {
  padding-bottom: 1em;
}
.cont ul li hr {
  border: none;
  border-top: rgb(145, 160, 205) dashed 1px;
  height: 1px;
}
.cont ul li input[type="text"],
.cont ul li input[type="email"],
.cont ul li select ,
.cont ul li textarea {
  width: 92vw;
  padding: .5em 0;
  font-size: 3.8vw;
}
.cont ul li textarea {
  width: 92vw;
  min-height: 5em;
}
.cont ul li:last-child {
  padding-top: 1em;
  text-align: center;
}
.cont ul li:last-child button {
  padding: 1em 4em;
}
.cont ul li span {
  display: block;
  color: rgb(210, 95, 0);
}
}
@media all and (min-width: 480px) {
  .cont ul {
    width: 60vw;
    margin: auto;
    font-size: 1vw;
    padding: .5em 0;
  }
  .cont ul li {
    padding-bottom: 1em;
  }
  .cont ul li hr {
    border: none;
    border-top: rgb(145, 160, 205) dashed 1px;
    height: 1px;
  }
  .cont ul li input[type="text"],
  .cont ul li input[type="email"],
  .cont ul li select ,
  .cont ul li textarea {
    font-size: 1vw;
  }
  .cont ul li textarea {
    min-width: 30em;
    min-height: 5em;
  }
  .cont ul li:last-child {
    padding-top: 1em;
  }
  .cont ul li:last-child button {
    padding: .5em 4em;
  }
  .cont ul li span {
    padding-left: 1em;
    color: rgb(210, 95, 0);
  }
}
</style>

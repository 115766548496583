<template>
  <div class="list">
    <aside>
      <div class="btns">
        <p>
          <template v-if="onlyCaution">
            <button v-on:click="setOnlyCaution(false)">要確認以外も閲覧</button>
          </template>
          <template v-else>
            <button v-on:click="setOnlyCaution(true)">要確認一覧（{{ cautionCount }}）</button>
          </template>
        </p>
        <p>
          <button v-on:click="expandAll">全部開く</button>
        </p>
        <p>
          <button v-on:click="closeAll">全部閉じる</button>
        </p>
      </div>
      <div class="legend">
        <ul>
          <li class="alert"><span>異常検知</span></li>
          <li class="active"><span>通常状態</span></li>
          <li class="sleeping"><span>睡眠中</span></li>
          <li class="offline"><span>オフライン</span></li>
        </ul>
        <p>上記ステータスの詳細は<a href="/help">ヘルプ</a>を御覧ください。</p>
      </div>
    </aside>

    <section class="list-cont">
      <div v-for="targetUserGroup in targetUserGroups" :key="targetUserGroup.groupName">
        <div v-if="listType === 0" class="list-pict">
          <div class="list-panel">
            <div v-if="expandingGroupNameList.includes(targetUserGroup.groupName)">
              <span v-on:click="toggleExpandingGroup(targetUserGroup.groupName)" class="linktxt"><img src="@/assets/img/icon-close.svg" alt="とじる" />とじる</span>
            </div>
            <div v-else>
              <span v-on:click="toggleExpandingGroup(targetUserGroup.groupName)" class="linktxt"><img src="@/assets/img/icon-open.svg" alt="ひらく" />ひらく</span>
            </div>
            <p>{{ targetUserGroup.groupName }}</p>
          </div>
          <ul v-if="expandingGroupNameList.includes(targetUserGroup.groupName)">
            <template v-for="targetUser in targetUserGroup.targetUsers" :key="targetUser.id">
              <li v-on:click="showUserDetail(targetUser.id)" :class='getStatusClass(targetUser.status)' v-if="!onlyCaution || targetUser.status === targetUserStatusConstant.inactive">
                <div class="id">{{ targetUser.room_name }}</div>
                <div class="name" v-if="nameVisibility">{{ targetUser.name }}</div>
                <div class="people-in-charge" v-if="peopleInChargeVisibility && targetUser.facility_user_names_in_charge.length > 0">担当：{{ renderPeopleInCharge(targetUser.facility_user_names_in_charge) }}</div>
              </li>
            </template>
          </ul>
        </div>
        <div v-if="listType === 1" class="list-txt">
          <div class="list-panel">
            <div v-if="expandingGroupNameList.includes(targetUserGroup.groupName)">
              <span v-on:click="toggleExpandingGroup(targetUserGroup.groupName)" class="linktxt"><img src="@/assets/img/icon-close.svg" alt="とじる" />とじる</span>
            </div>
            <div v-else>
              <span v-on:click="toggleExpandingGroup(targetUserGroup.groupName)" class="linktxt"><img src="@/assets/img/icon-open.svg" alt="ひらく" />ひらく</span>
            </div>
            <p>{{ targetUserGroup.groupName }}</p>
          </div>
          <ol v-if="expandingGroupNameList.includes(targetUserGroup.groupName)">
            <template v-for="targetUser in targetUserGroup.targetUsers" :key="targetUser.id">
              <li v-on:click="showUserDetail(targetUser.id)" :class='getStatusClass(targetUser.status)'>
                <p>
                  <span class="id">【{{ targetUser.room_name }}】</span>
                  <span class="name" v-if="nameVisibility">{{ targetUser.name }}</span>
                  <span class="people-in-charge" v-if="peopleInChargeVisibility && targetUser.facility_user_names_in_charge.length > 0">担当：{{ renderPeopleInCharge(targetUser.facility_user_names_in_charge) }}</span>
                </p>
              </li>
            </template>
          </ol>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { targetUserStatus } from '../../constants'

export default {
  props: {
    targetUserGroups: {
      type: Array,
      required: true
    },
    listType: {
      type: Number,
      required: true
    },
    nameVisibility: {
      type: Boolean,
      required: true
    },
    peopleInChargeVisibility: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      targetUserStatusConstant: targetUserStatus,
      cautionCount: this.countCaution(),
      onlyCaution: false,
      expandingGroupNameList: this.targetUserGroups.map(group => group.groupName),
    }
  },
  watch: {
    targetUserGroups: function() {
      this.cautionCount = this.countCaution()
      this.expandAll()
    }
  },
  methods: {
    countCaution: function() {
      let newCautionCount = 0
      this.targetUserGroups.forEach(group => {
        group.targetUsers.forEach(targetUser => {
          if (targetUser.status === targetUserStatus.inactive) {
            newCautionCount += 1
          }
        })
      })
      return newCautionCount
    },
    setOnlyCaution: function(value) {
      this.onlyCaution = value
    },
    showUserDetail: function(targetUserId) {
      this.$emit("showUserDetail", targetUserId)
    },
    renderSinceLastExisted: function(sinceLastExisted) {
      if (sinceLastExisted < 60) {
        return '通常'
      }
      const minute = Math.floor(sinceLastExisted / 60)
      if (minute > 60) {
        return '異常 1時間以上経過'
      } else {
        return `異常 ${minute}分経過`
      }
    },
    renderPeopleInCharge: function(facilityUserNamesInCharge) {
      return facilityUserNamesInCharge.join(',')
    },
    getStatusClass: function(currentStatus) {
      if (currentStatus === targetUserStatus.active) {
        return 'active'
      } else if (currentStatus === targetUserStatus.inactive) {
        return 'alert'
      } else if (currentStatus === targetUserStatus.sleeping) {
        return 'sleeping'
      } else if (currentStatus === targetUserStatus.offline) {
        return 'offline'
      }
    },
    toggleExpandingGroup: function(groupName) {
      if (this.expandingGroupNameList.includes(groupName)){
        this.expandingGroupNameList.remove
        this.expandingGroupNameList = this.expandingGroupNameList.filter(item => item !== groupName)
      } else {
        this.expandingGroupNameList.push(groupName)
      }
    },
    expandAll: function() {
      this.expandingGroupNameList = this.targetUserGroups.map(group => group.groupName)
    },
    closeAll: function() {
      this.expandingGroupNameList = []
    }
  }
}
</script>

<style scoped>
@media all and (max-width: 480px) {
  aside {
  }
  .btns {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding-bottom: 4vw;
  }
  .btns p {
  }
  .list .appearance {
  font-size: 3.8vw;
  margin: 1em 0 1em;
  }
  .list .appearance ul {
    display: flex;
    flex-flow: row wrap;
  }
  .list .appearance ul li {
    padding-right: 1em;
  }
  .legend ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    font-size: 3.4vw;
  }
  .legend ul li {
    padding-left: 1em;
  }
  .legend ul li.alert span::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    background: rgb(240, 150, 50);
    margin-right: .5em;
  }
  .legend ul li.active span::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    background: rgb(255, 255, 255);
    border: rgb(200,200,200) solid 1px;
    margin-right: .5em;
  }
  .legend ul li.sleeping span::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    background: rgb(155,200,255);
    margin-right: .5em;
  }
  .legend ul li.offline span::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    background: rgb(200, 200, 200);
    margin-right: .5em;
  }
  .legend p {
    text-align: right;
    font-size: 3vw;
  }
  .list-panel {
    display: flex;
    flex-flow: row wrap;
    font-size: 3.2vw;
    padding-bottom: .6em;
  }
  .list-panel div {
    padding-right: 1.5em;
  }
  .list-panel div span {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .list-panel div span img {
    width: 1.6em;
    margin-right: .2em;
    opacity: .6;
    transition: .3s;
  }
    .list-panel div span:hover img {
      opacity: 1;
  }
  .list-panel p {
    padding-right: 1em;
  }
}
@media all and (min-width: 480px) {
  aside {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .btns {
    display: flex;
    flex-flow: row wrap;
  }
  .btns p {
    padding-right: 1vw;
  }
  .list .appearance {
    font-size: 1.2vw;
    margin: -2em 0 1em;
  }
  .list .appearance ul {
    display: flex;
    flex-flow: row wrap;
  }
  .list .appearance ul li {
    padding-right: 1em;
  }
  .list .appearance ul li label {
    cursor: pointer;
  }
  .legend ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    font-size: 1vw;
  }
  .legend ul li {
    padding-left: 1em;
  }
  .legend ul li.alert span::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    background: rgb(240, 150, 50);
    margin-right: .5em;
  }
  .legend ul li.active span::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    background: rgb(255, 255, 255);
    border: rgb(200,200,200) solid 1px;
    margin-right: .5em;
  }
  .legend ul li.sleeping span::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    background: rgb(155,200,255);
    margin-right: .5em;
  }
  .legend ul li.offline span::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    background: rgb(200, 200, 200);
    margin-right: .5em;
  }
  .legend p {
    text-align: right;
    font-size: 1vw;
  }
  .list-panel {
    display: flex;
    flex-flow: row wrap;
    font-size: 1vw;
    padding-bottom: .6em;
  }
  .list-panel div {
    padding-right: 1.5em;
  }
  .list-panel div span {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .list-panel div span img {
    width: 1.6em;
    margin-right: .2em;
    opacity: .6;
    transition: .3s;
  }
  .list-panel div span:hover img {
    opacity: 1;
  }
  .list-panel p {
    padding-right: 1em;
  }
}
</style>

<template>
  <Header />
  <main>
    <div class="cont">
      <BackButton/>
      <h1>会社編集</h1>

      <label>ステータス</label>
      <select v-model="company.status">
        <option
          v-for="item in companyStatusItems"
          :value="item.id"
          :key="item.id"
          :selected="company.status === item.id"
        >
          {{ item.label }}
        </option>
      </select>
      <br>

      <div v-if="company.id">
        <label>会社ID:{{ company.id }}</label>
        <br>
      </div>

      <label>会社名</label>
      <input
        id="companyName"
        placeholder="会社名"
        autocomplete="off"
        type="text"
        v-model="company.name"
      >
      <br>

      <label>会社名（ふりがな）</label>
      <input
        id="companyNameHiragana"
        placeholder="会社名（ふりがな）"
        autocomplete="off"
        type="text"
        v-model="company.name_hiragana"
      >
      <br>

      <label>会社所在地</label>
      <input
        id="companyPostalCode"
        placeholder="郵便番号"
        autocomplete="off"
        type="text"
        v-model="company.postal_code"
      >
      <input
        id="companyAddress"
        placeholder="住所"
        autocomplete="off"
        type="text"
        v-model="company.address"
      >
      <br>

      <label>会社所在地（ふりがな）</label>
      <input
        id="companyAddressHiragana"
        placeholder="会社所在地（ふりがな）"
        autocomplete="off"
        type="text"
        v-model="company.address_hiragana"
      >
      <br>

      <label>電話番号</label>
      <input
        id="companyPhoneNumber"
        placeholder="電話番号"
        autocomplete="off"
        type="text"
        v-model="company.phone_number"
      >
      <br>

      <div v-for="(emergencyContact, index) in emergencyContacts" :key="index">
        <label>緊急連絡先{{ index+1 }}</label>
        <input
          :id="'emergencyContactName' + index"
          placeholder="氏名"
          autocomplete="off"
          type="text"
          v-model="emergencyContact.name"
        >
        <input
          :id="'emergencyContactNameHiragana' + index"
          placeholder="氏名(ふりがな)"
          autocomplete="off"
          type="text"
          v-model="emergencyContact.name_hiragana"
        >
        <input
          :id="'emergencyContactNameHiragana' + index"
          placeholder="電話番号"
          autocomplete="off"
          type="text"
          v-model="emergencyContact.phone_number"
        >
        <br>
      </div>

      <label>備考</label>
      <textarea
        placeholder="備考"
        v-model="company.remarks"
      ></textarea>
      <br>

      <button v-on:click="saveCompany()">内容を保存</button>
      <br>

      <div v-if="company.id">
        <h1>会社管理者</h1>
        <table>
          <thead>
            <tr>
              <th>メールアドレス</th>
              <th>氏名</th>
              <th>ステータス</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="adminUser in companyAdminUsers" :key="adminUser.id">
              <td>
                <input
                  placeholder="メールアドレス"
                  autocomplete="off"
                  type="text"
                  v-model="adminUser.email"
                >
              </td>
              <td>
                <input
                  placeholder="氏名"
                  autocomplete="off"
                  type="text"
                  v-model="adminUser.name"
                >
              </td>
              <td>
                <select v-model="adminUser.status">
                  <option
                    v-for="item in companyAdminUserStatusItems"
                    :value="item.id"
                    :key="item.id"
                    :selected="adminUser.status === item.id"
                  >
                    {{ item.label }}
                  </option>
                </select>
              </td>
              <td>
                <button v-on:click="updateAdminUser(adminUser)">保存</button>
              </td>
              <td>
                <label v-if="adminUser.updateResponseText">{{ adminUser.updateResponseText }}</label>
              </td>
            </tr>
            <tr>
              <td>
                <input
                  placeholder="メールアドレス"
                  autocomplete="off"
                  type="text"
                  v-model="newCompanyAdminUser.email"
                >
              </td>
              <td>
                <input
                  placeholder="氏名"
                  autocomplete="off"
                  type="text"
                  v-model="newCompanyAdminUser.name"
                >
              </td>
              <td>
                <input
                  placeholder="パスワード"
                  autocomplete="off"
                  type="text"
                  v-model="newPassword"
                >
              </td>
              <td>
                <select v-model="newCompanyAdminUser.status">
                  <option
                    v-for="item in companyAdminUserStatusItems"
                    :value="item.id"
                    :key="item.id"
                    :selected="newCompanyAdminUser.status === item.id"
                  >
                    {{ item.label }}
                  </option>
                </select>
              </td>
              <td>
                <button v-on:click="addAdminUser()">会社管理者の新規作成</button>
              </td>
            </tr>
          </tbody>
          <th></th>
        </table>
        <label v-if="addAdminUserError">{{ addAdminUserError }}</label>
      </div>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import { apiGet, apiPost } from '../../api'
import { companyStatus, userStatus } from '../../constants'

export default {
  name: 'EditCompany',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      emergencyContactCount: 3,
      company: {},
      companyStatusItems: [
        { id: companyStatus.active, label: '契約中' },
        { id: companyStatus.cancel, label: '契約破棄' },
        { id: companyStatus.inactive, label: '中断'},
      ],
      emergencyContacts: [],
      companyAdminUsers: [],
      companyAdminUserStatusItems: [
        { id: userStatus.active, label: '利用中' },
        { id: userStatus.inactive, label: '利用停止' },
      ],
      newCompanyAdminUser: {
        email: '',
        name: '',
        status: userStatus.active,
      },
      newPassword: '',
      addAdminUserError: null,
    }
  },
  mounted() {
    this.loadCompany()
  },
  methods: {
    loadCompany: async function() {
      if (typeof(this.$route.query.id) === 'undefined') {
        this.company = {
          address: '',
          address_hiragana: '',
          name: '',
          name_hiragana: '',
          phone_number: '',
          postal_code: '',
          remarks: '',
          status: companyStatus.active
        }
        this.setEmptyEmergencyContacts()
        return
      }

      try {
        const response = await apiGet(`/api/admin/company?id=${this.$route.query.id}`, this.$router.app.$cookies)

        this.company = response.data.company
        this.emergencyContacts = response.data.emergency_contacts
        this.setEmptyEmergencyContacts()
        this.companyAdminUsers = response.data.admin_users
      } catch (error) {
        console.log(error.value)
      }
    },
    saveCompany: async function() {
      const body = {
        company: this.company,
        emergency_contacts: this.emergencyContacts
      }
      const response = await apiPost('/api/admin/company', body, this.$router.app.$cookies)
      if (response.data.success) {
        this.$router.push(`/admin/edit_company?id=${response.data.company_id}`)
      }
    },
    updateAdminUser: async function(adminUser) {
      const response = await apiPost('/api/admin/company/update_admin_user', adminUser, this.$router.app.$cookies)
      if ('error' in response.data) {
        adminUser.updateResponseText = response.data.error
      } else {
        adminUser.updateResponseText = '管理者情報をアップデートしました。'
      }
    },
    addAdminUser: async function() {
      const body = {
        company_id: this.$route.query.id,
        user: this.newCompanyAdminUser,
        password: this.newPassword,
      }
      const response = await apiPost('/api/admin/company/add_admin_user', body, this.$router.app.$cookies)
      if ('error' in response.data) {
        this.addAdminUserError = response.data.error
      } else {
        this.newCompanyAdminUser = {
          email: '',
          name: '',
          status: 1,
        },
        this.loadCompany()
      }
    },
    setEmptyEmergencyContacts: function() {
      while (this.emergencyContacts.length < this.emergencyContactCount) {
        this.emergencyContacts.push({
          name: '',
          name_hiragana: '',
          phone_number: '',
          company_id: this.company.id
        })
      }
    }
  }
}
</script>

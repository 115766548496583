<template>
  <article v-if="isAdmin" class="formitems">
    <div class="btns">
      <button v-on:click="showFacilityManager">モニタリング</button>
      <button v-on:click="showFacilityDetail">施設詳細</button>
      <button v-on:click="showResidents">入居者一覧</button>
      <button v-on:click="showFacilityAdmins">施設担当者一覧</button>
      <button v-on:click="showFacilityLabels">ラベル・備考を編集</button>
    </div>
  </article>
</template>

<script>
import { apiGet } from '../../api'

export default {
  name: 'FacilityAdminMenu',
  props: {
    facilityId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isAdmin: false
    };
  },
  mounted() {
    this.checkIsAdmin()
  },
  methods: {
    showFacilityManager: function() {
      this.$router.push(`/facility_manager?id=${this.facilityId}`)
    },
    showFacilityDetail: function() {
      this.$router.push(`/facility_admin/facility_detail?facility_id=${this.facilityId}`)
    },
    showResidents: function() {
      this.$router.push(`/facility_admin/residents?facility_id=${this.facilityId}`)
    },
    showFacilityAdmins: function() {
      this.$router.push(`/facility_admin/users?facility_id=${this.facilityId}`)
    },
    showFacilityLabels: function() {
      this.$router.push(`/facility_admin/facility_labels?facility_id=${this.facilityId}`)
    },
    checkIsAdmin: async function() {
      try {
        const response = await apiGet(`/api/user_detail/is_facility_admin?facility_id=${this.facilityId}`, this.$router.app.$cookies)
        this.isAdmin = response.data.is_facility_admin
      } catch (error) {
        console.error(error)
      }
    },
  }
}
</script>

<style scoped>
@media all and (max-width: 480px) {
  .btns {
    width: 100%;
    margin: 8vw auto;
    padding: 0 1vw 3vw;
  }
  .btns button {
    display: block;
    width: 100%;
    margin: 0 auto 4vw;
  }
}
@media all and (min-width: 480px) {
  .btns {
    padding: 0 1vw 3vw;
  }
  .btns button {
    margin-right: 1em;
  }
}
</style>

<template>
  <Header />
  <main>
    <div class="cont">
      <FacilityAdminMenu :facilityId="Number($route.query.facility_id)"/>
      <h1>入居者詳細</h1>
      <article class="formitems">
        <div class="form">
          <p>
            <label>姓名</label>
            <input
              id="name"
              placeholder="姓名"
              autocomplete="off"
              type="text"
              v-model="resident.name"
            >
          </p>

          <p class="multiple">
            <label>性別</label>
            <span>
              <label><input type="radio" :value="gender.male" v-model="resident.gender" />
              男性</label>
              <label><input type="radio" :value="gender.female" v-model="resident.gender" />
              女性</label>
              <label><input type="radio" :value="gender.unknown" v-model="resident.gender" />
              性別未設定</label>
            </span>
          </p>

          <p>
            <label>部屋名</label>
            <input
              id="room_name"
              placeholder="部屋名"
              autocomplete="off"
              type="text"
              v-model="resident.room_name"
            >
          </p>

          <p class="date_picker_container">
            <label>生年月日</label>
            <VueDatePicker
                locale="ja"
                format="yyyy/MM/dd"
                :auto-apply="true"
                :max-date="new Date()"
                :enable-time-picker="false"
                v-model="resident.birthday"
            ></VueDatePicker>

          </p>

          <p class="age"><label>年齢</label><span>{{ this.calculateAge() }}</span></p>

          <label>担当者</label>
          <div class="facilityUsers">
            <span v-for="facilityUser in facilityUsers" :key="facilityUser.id">
              <label><input type="checkbox" :value="facilityUser.id" v-model="facilityUserIdInChargeList" />{{ facilityUser.name }}</label>
            </span>
          </div>

          <p>
            <label>ラベル</label>
            <select v-model="resident.facility_label_id">
              <option value="null" selected>指定なし</option>
              <option v-for="label in labels" :key="label.id" :value="label.id">{{ label.main }} {{ label.sub }}</option>
            </select>
          </p>

          <hr>
          <em>緊急連絡先</em>
          <p>
            <label>氏名</label>
            <input
              id="emergency_contact_name"
              placeholder="氏名"
              autocomplete="off"
              type="text"
              v-model="resident.emergency_contact_name"
            >
          </p>

          <p>
            <label>続柄</label>
            <input
              id="emergency_contact_relation"
              placeholder="続柄"
              autocomplete="off"
              type="text"
              v-model="resident.emergency_contact_relation"
            >
          </p>

          <p>
            <label>連絡先メールアドレス</label>
            <input
              id="emergency_contact_email"
              placeholder="連絡先メールアドレス"
              autocomplete="off"
              type="email"
              v-model="resident.emergency_contact_email"
            >
          </p>

          <p>
            <label>連絡先電話番号</label>
            <input
              id="emergency_contact_phone_number"
              placeholder="連絡先電話番号"
              autocomplete="off"
              type="tel"
              v-model="resident.emergency_contact_phone_number"
            >
          </p>
          <div class="send">
            <p>
              <button v-on:click="saveResident()">内容を保存</button><br>
              <label v-if:="saveSuccess">保存しました</label>
              <label v-if:="error" class="error">{{ error }}</label>
            </p>
          </div>
        </div>
      </article>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import FacilityAdminMenu from './FacilityAdminMenu'
import { apiGet, apiPost } from '../../api'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { gender } from '../../constants'
import { convertToHankaku } from '../../stringUtil'

export default {
  name: 'EditResident',
  components: {
    Header,
    Footer,
    FacilityAdminMenu,
    VueDatePicker
  },
  data() {
    return {
      resident: {
        facility_id: 0
      },
      gender: gender,
      saveSuccess: false,
      labels: [],
      facilityUsers: [],
      facilityUserIdInChargeList: [],
      error: null,
    }
  },
  async mounted() {
    await this.loadResident()
    this.loadLabels()
    this.loadFacilityUsers()
  },
  methods: {
    loadResident: async function() {
      const response = await apiGet(`/api/target_user?target_user_id=${this.$route.query.id}`, this.$router.app.$cookies)
      this.resident = response.data
      if (this.resident.facility_user_id_in_charge_list_json !== null) {
        this.facilityUserIdInChargeList = JSON.parse(this.resident.facility_user_id_in_charge_list_json)
      }
    },
    saveResident: async function() {
      var errors = this.checkFormError()
      if (errors.length > 0) {
        this.error = errors.join()
        return
      }

      if (this.resident.facility_label_id === "null") {
        this.resident.facility_label_id = null
      }
      this.resident.room_name = convertToHankaku(this.resident.room_name)
      this.resident.emergency_contact_phone_number = convertToHankaku(this.resident.emergency_contact_phone_number)
      this.resident.facility_user_id_in_charge_list_json = JSON.stringify(this.facilityUserIdInChargeList)

      const body = {
        target_user: this.resident
      }
      this.saveSuccess = true
      const response = await apiPost('/api/target_user', body, this.$router.app.$cookies)
      if (response.data.success) {
        this.$router.push(this.$router.options.history.state.back)
      }
    },
    calculateAge() {
      if (typeof(this.resident.birthday) === 'undefined' || this.resident.birthday === null) {
        return ''
      }
      const birthDate = new Date(this.resident.birthday)
      const currentDate = new Date();
      let age = currentDate.getFullYear() - birthDate.getFullYear()
      if (currentDate.getMonth() < birthDate.getMonth() || (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
        age--
      }

      return `${age}歳`
    },
    loadLabels: async function() {
      const response = await apiGet(`/api/facility_label?facility_id=${this.resident.facility_id}`, this.$router.app.$cookies)
      this.labels = response.data
    },
    loadFacilityUsers: async function() {
      const response = await apiGet(`/api/user_facility_relation?facility_id=${this.resident.facility_id}`, this.$router.app.$cookies)
      this.facilityUsers = response.data
    },
    checkFormError: function() {
      const errors = []
      if (this.resident.emergency_contact_phone_number !== '') {
        const tel_hankaku = convertToHankaku(String(this.resident.emergency_contact_phone_number))
        if (!Number.isInteger(Number(tel_hankaku))) {
          errors.push('連絡先電話番号はハイフン無しの数字を入力してください。')
        }
      }
      return errors
    },
  }
}
</script>

<style scoped>
@media all and (max-width: 480px) {
  .form {
    padding: 0 1vw 3vw;
    font-size: 3vw;
  }
  .form p {
    padding: 0 0 1em;
  }
  .form input {
    font-size: 3vw;
  }
  .form input[type="text"],
  .form input[type="email"],
  .form input[type="tel"] {
    width: 99%;
  }
  .form label {
    display: inline-block;
    min-width: 4em;
  }
  .form em {
    display: block;
    font-size: 3vw;
    font-style: normal;
  }
  .form hr {
    height: 1px;
    padding: 0;
    border: 0;
    border-bottom: rgb(100, 145, 200) dashed 1px;
  }
  .form .facilityUsers {
    display: flex;
    flex-flow: row wrap;
    padding: .1em 0 2em;
  }
  .form .facilityUsers span {
    display: inline-block;
    padding-right: 1.5em;
  }
  .form .send {
    padding: 2em 0 0;
  }
  .form .send p {
    text-align: center;
  }
}
@media all and (min-width: 480px) {
  .form {
    padding: 0 1vw 3vw;
    font-size: 1vw;
  }
  .form p {
    padding: 0 0 1em;
  }
  .form input {
    font-size: 1vw;
  }
  .form label {
    display: inline-block;
    min-width: 4vw;
  }
  .form em {
    display: block;
    font-size: 1.1vw;
    font-style: normal;
  }
  .form hr {
    height: 1px;
    padding: 0;
    border: 0;
    border-bottom: rgb(100, 145, 200) dashed 1px;
  }
  .form .facilityUsers {
    display: flex;
    flex-flow: row wrap;
    padding: .1em 0 2em;
  }
  .form .facilityUsers span {
    display: inline-block;
    padding-right: 1.5em;
  }
  .form .multiple label,
  .form .facilityUsers span label {
    cursor: pointer;
  }
  .form .facilityUsers span input {
    margin-right: .2em;
  }
  .dp__theme_light {
    --dp-font-size: 1vw;
  }
  .date_picker_container {
    width: 220px;
  }
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'

import router from './router'
import './assets/css/style.css';
import VueElementLoading from 'vue-element-loading'
import BackButton from './components/BackButton'

const app = createApp(App)

app.use(router, axios, VueAxios);
router.app = app;

app.use(VueCookies);
app.component('VueElementLoading', VueElementLoading)
app.component('BackButton', BackButton)

app.mount('#app')

import axios from 'axios'
import jwt_decode from 'jwt-decode'

const apiGet = async function(url, cookies) {
  let accessToken = cookies.get('AccessToken')
  let refreshToken = cookies.get('RefreshToken')
  if (refreshToken === null) {
    location.href = `/login?redirect=${window.location.pathname}`
    return
  }
  accessToken = await _tokenRefreshIfNeeded(cookies)

  const response = await axios.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    }
  })
  return response
}

const apiPost = async function(url, body, cookies) {
  let accessToken = cookies.get('AccessToken')
  let refreshToken = cookies.get('RefreshToken')
  if (refreshToken === null) {
    location.href = `/login?redirect=${window.location.pathname}`
    return
  }
  accessToken = await _tokenRefreshIfNeeded(cookies)

  const response = await axios.post(url, body, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
  return response
}

const apiDelete = async function(url, body, cookies) {
  let accessToken = cookies.get('AccessToken')
  let refreshToken = cookies.get('RefreshToken')
  if (refreshToken === null) {
    location.href = `/login?redirect=${window.location.pathname}`
    return
  }
  accessToken = await _tokenRefreshIfNeeded(cookies)

  const response = await axios.delete(url, {
    data: body,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    }
  })
  return response
}

const _tokenRefreshIfNeeded = async function(cookies) {
  let accessToken = cookies.get('AccessToken')
  let refreshToken = cookies.get('RefreshToken')

  const decoded = jwt_decode(accessToken, {complete: true})
  const exp = decoded.exp * 1000
  const now = new Date().getTime()
  if (now > exp) {
    const refreshResponse = await axios.post('/api/auth/refresh', {
      access_token: accessToken,
      refresh_token: refreshToken
    })
    accessToken = refreshResponse.data.access_token
    refreshToken = refreshResponse.data.refresh_token
    cookies.config(60 * 60 * 24 * 30,'')
    cookies.set('AccessToken', accessToken)
    cookies.set('RefreshToken', refreshToken)
  }
  return accessToken
}

export { apiGet, apiPost, apiDelete }

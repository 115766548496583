<template>
  <Header />
  <main>
    <div class="cont">
      <BackButton/>
      <h1>ログダウンロード</h1>

      <label>部屋番号</label>
      <input
        id="searchRoomName"
        placeholder="部屋番号"
        autocomplete="off"
        type="text"
        v-model="searchRoomName"
      >

      <label>氏名</label>
      <input
        id="searchName"
        placeholder="氏名"
        autocomplete="off"
        type="text"
        v-model="searchName"
      >
      <br>

      <label>入居年月日</label>
      <VueDatePicker
        locale="ja"
        format="yyyy-MM-dd"
        :auto-apply="true"
        :max-date="createdAtRangeEnd || new Date()"
        :enable-time-picker="false"
        v-model="createdAtRangeStart"
      ></VueDatePicker>
      から
      <VueDatePicker
        locale="ja"
        format="yyyy-MM-dd"
        :auto-apply="true"
        :min-date="createdAtRangeStart"
        :max-date="new Date()"
        :enable-time-picker="false"
        v-model="createdAtRangeEnd"
      ></VueDatePicker>

      <button v-on:click="searchLog">検索</button>
      <br>

      <LogTargetList :logTargetUsers="logTargetUsers"></LogTargetList>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '../Header'
import Footer from '../Footer'
import LogTargetList from './LogTargetList'
import { apiGet } from '../../api'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: 'LogDownload',
  components: {
    Header,
    Footer,
    LogTargetList,
    VueDatePicker
  },
  data() {
    return {
      searchRoomName: '',
      searchName: '',
      createdAtRangeStart: null,
      createdAtRangeEnd: null,
      logTargetUsers: []
    }
  },
  methods: {
    searchLog: async function() {
      try {
        let apiUrl = `/api/log/search?facility_id=${this.$route.query.facility_id}&room_name=${this.searchRoomName}&name=${this.searchName}`
        if (this.createdAtRangeStart !== null) {
          this.createdAtRangeStart.setHours(0,0,0,0)
          const startUnixTime = this.createdAtRangeStart.getTime() / 1000
          apiUrl += `&created_at_start=${startUnixTime}`
        }
        if (this.createdAtRangeEnd !== null) {
          this.createdAtRangeEnd.setHours(23,59,59,0)
          const endUnixTime = this.createdAtRangeEnd.getTime() / 1000
          apiUrl += `&created_at_end=${endUnixTime}`
        }

        const response = await apiGet(
          apiUrl,
          this.$router.app.$cookies
        )
        this.logTargetUsers = response.data
      } catch (error) {
        console.log(error.value)
      }
    }
  }
}
</script>

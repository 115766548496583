<template>
  <header>
  <div class="hcont">
    <h1><a href="/">CareSense</a></h1>
    <nav>
      <div class="modal btn">
        <label class="modal_btn" for="head-nav"><img src="@/assets/img/btn-menu.svg" alt="メニュー" /></label>
        <input type="checkbox" id="head-nav" class="my_modal1">
        <label class="modal_overlay" for="head-nav"></label>
        <div class="modal_body1">
          <div class="modal_header">
            メニュー
            <label class="modal_close" for="head-nav">
              <div class="modal_close_icon"><span></span></div>
            </label><!-- 閉じるボタン -->
          </div>
          <div class="modal_content">
            <ul>
              <li><a href="/">会社・施設選択に戻る</a></li>
              <li><a href="/help">ヘルプ</a></li>
              <li><a href="/inquiry">お問い合わせ</a></li>
              <li><a href="/term_of_use">利用規約</a></li>
              <li><a href="/privacy_policy">プライバシポリシー</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="isLogin()" class="log btn"><a v-on:click="logout"><img src="@/assets/img/btn-logout.svg" alt="ログアウト" /></a></div>
    </nav>
  </div>
</header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      isOpeningPopupMenu: false
    }
  },
  methods: {
    menu: function() {
      this.isOpeningPopupMenu = !this.isOpeningPopupMenu
    },
    logout: function() {
      this.$router.app.$cookies.remove('AccessToken')
      this.$router.app.$cookies.remove('RefreshToken')
      this.$router.push('/login')
    },
    isLogin: function() {
      return this.$router.app.$cookies.get('AccessToken') !== null
    }
  }
}
</script>

<template>
  <div class="formtarget">
    <div class="main">
      <p>
        <label>部屋名</label>
        <input
          id="searchRoomName"
          placeholder="部屋名"
          autocomplete="off"
          type="text"
          v-model="searchRoomName"
        >
      </p>
      <p>
        <label>氏名</label>
        <input
          id="searchName"
          placeholder="氏名"
          autocomplete="off"
          type="text"
          v-model="searchName"
        >
      </p>
      <p><label>&nbsp;</label>
      <button v-on:click="searchResidents">検索</button></p>
    </div>
  </div>
</template>

<script>
import { apiGet } from '../../api'

export default {
  name: 'TargetUserSearchForm',
  data() {
    return {
      searchRoomName: '',
      searchName: '',
      selectedMainLabel: '',
      selectedSubLabel: '',
      mainLabels: [],
      subLabels: [],
      hitResidents: [],
    }
  },
  async mounted() {
    this.loadLabels()
  },
  methods: {
    searchResidents: async function() {
      let query = `facility_id=${this.$route.query.id}`
      if (this.searchRoomName !== '') {
        query += `&room_name=${encodeURIComponent(this.searchRoomName)}`
      }
      if (this.searchName !== '') {
        query += `&name=${encodeURIComponent(this.searchName)}`
      }
      if (this.selectedMainLabel !== '') {
        query += `&main_label=${encodeURIComponent(this.selectedMainLabel)}`
      }
      if (this.selectedSubLabel !== '') {
        query += `&sub_label=${encodeURIComponent(this.selectedSubLabel)}`
      }
      const response = await apiGet(`/api/target_user/search?${query}`, this.$router.app.$cookies)
      this.$emit("searchTargetUsers", response.data, query)
    },
    loadLabels: async function() {
      const response = await apiGet(`/api/facility_label?facility_id=${this.$route.query.id}`, this.$router.app.$cookies)
      const fetchedMainLabels = []
      const fetchedSubLabels = []
      response.data.forEach(label => {
        if (label.main !== '' && !fetchedMainLabels.includes(label.main)) {
          fetchedMainLabels.push(label.main)
        }
        if (label.sub !== '' && !fetchedSubLabels.includes(label.sub)) {
          fetchedSubLabels.push(label.sub)
        }
      })

      this.mainLabels = fetchedMainLabels
      this.subLabels = fetchedSubLabels
    }
  }
}
</script>

<style scoped>
@media all and (max-width: 480px) {
  .formtarget {
    width: 100%;
    padding: 0 1vw 3vw;
  }
  .formtarget > div {
    display: flex;
    flex-flow: row wrap;
    font-size: 3vw;
    padding-bottom: 1em;
  }
  .formtarget div p {
    width: 100%;
    text-align: center;
  }
  .formtarget div label {
    display: block;
    text-align: left;
    width: 100%;
    margin: auto;
    padding-top: 1em;
  }
  .formtarget .main p:last-child label {
    padding-top: 0;
  }
  .formtarget div input,
  .formtarget div select {
    font-size: 3vw;
    width: 100%;
    margin: auto;
    padding: .2em;
  }
  .formtarget .btn {
    text-align: center;
  }
  .formtarget .btn button {
    display: block;
    margin: auto;
  }
}
@media all and (min-width: 480px) {
  .formtarget {
    padding: 0 1vw 3vw;
  }
  .formtarget > div {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-size: 1vw;
    padding-bottom: 1em;
  }
  .formtarget div p {
    padding-right: 1em;
  }
  .formtarget div label {
    display: block;
  }
  .formtarget div input,
  .formtarget div select {
    font-size: 1vw;
    padding: .2em;
  }
  .formtarget .btn {
    padding: 0 0 0 20vw;
  }
}
</style>
